import React from "react"
import { FaCcStripe, FaCreditCard, FaAlipay } from "react-icons/fa"
export default [
  {
    id: 1,
    text: "홈",
    textEng: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "회사 소개",
    textEng: "About",
    url: "/about",
  },
  {
    id: 3,
    text: "사업 소개",
    textEng: "Projects",
    url: "/projects",
  },
  // {
  //   id: 4,
  //   text: "문의하기",
  //   url: "/contact",
  // },
  //{
  //  id: 5,
  //  text: "프로젝트 샘플",
  //  url: "/samples",
  //},
]
