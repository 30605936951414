import React, { useState } from "react"
import sublinks_ko from "../constants/links-ko"
import sublinks from "../constants/links"

const GatsbyContext = React.createContext()

//Provider, Consumer

const GatsbyProvider = ({ children }) => {
  const [defaultLang, setDefaultLang] = useState(null)
  const [language, setLanguage] = useState(null)
  const [links, setLinks] = useState(sublinks_ko)

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const showSidebar = () => {
    setIsSidebarOpen(true)
  }
  const hideSidebar = () => {
    setIsSidebarOpen(false)
  }

  return (
    <GatsbyContext.Provider
      value={{
        defaultLang,
        setDefaultLang,
        language,
        setLanguage,
        isSidebarOpen,
        links,
        setLinks,
        showSidebar,
        hideSidebar,
      }}
    >
      {children}
    </GatsbyContext.Provider>
  )
}

export { GatsbyContext, GatsbyProvider }
