import React from "react"
import { FaCcStripe, FaCreditCard, FaAlipay } from "react-icons/fa"
export default [
  {
    id: 1,
    text: "home",
    textEng: "Home",
    url: "/en",
  },
  {
    id: 2,
    text: "about",
    textEng: "About",
    url: "/about",
  },
  {
    id: 3,
    text: "projects",
    textEng: "Projects",
    url: "/projects",
  },
  //{
  //  id: 4,
  //  text: "contact us",
  //  url: "/contact",
  //},
  //{
  //  id: 5,
  //  text: "samples",
  //  url: "/samples",
  //},
]
